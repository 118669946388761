
(function (fbConfig) {
  'use strict';

  angular.module('firebase.config.file', [])
    .config(firebaseConfig);

  firebaseConfig.$inject = ['$windowProvider'];
  function firebaseConfig($windowProvider) {
    // Initialize Firebase
    $windowProvider.$get().firebase.initializeApp(fbConfig);
  }
  // eslint-disable-next-line no-inline-comments
}({"apiKey":"AIzaSyDMrP9xSV8woT6-lt-TBiyMkSy0r3EiHAs","databaseURL":"https://fiery-heat-6939.firebaseio.com","storageBucket":"fiery-heat-6939.appspot.com","authDomain":"fiery-heat-6939.firebaseapp.com","messagingSenderId":"101062618190","projectId":"fiery-heat-6939"}));
